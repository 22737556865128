export default {
  cakes: [
    {
      id: '002',
      korName: '리얼 스트로베리 쉬폰 케이크',
      engName: 'real strawberry chiffon cake',
      imageUri: require('../../assets/images/menu/bakery/cake/002.png'),
      price: 46000,
      desc:
        '생딸기와 생크림을 두 겹으로 쌓아 더욱 부드럽고 촉촉한 쉬폰 케이크\n(계절 과일 수급 상황에 따라 데코레이션 일부가 변동될 수 있습니다.)',
    },
    {
      id: '003',
      korName: '듀얼 카카오 자허 토르테',
      engName: 'dual cacao sacher torte',
      imageUri: require('../../assets/images/menu/bakery/cake/003.png'),
      price: 43000,
      desc: '살구잼을 레이어드 한 초콜릿 시트에 다크초콜릿을 더해 진한 초콜릿 풍미를 느낄 수 있는 케이크',
    },
    {
      id: '005',
      korName: '모카 쉬폰 케이크',
      engName: 'mocha chiffon cake',
      imageUri: require('../../assets/images/menu/bakery/cake/005.png'),
      price: 43000,
      desc: '촉촉한 시트 사이로 느껴지는 생크림과 커피향의 은은한 조화가 어울리는 케이크',
    },
    {
      id: '006',
      korName: '얼그레이 쉬폰 케이크',
      engName: 'earl grey chiffon cake',
      imageUri: require('../../assets/images/menu/bakery/cake/006.png'),
      price: 43000,
      desc: '은은한 홍차 향과 부드러운 생크림이 입안 가득 퍼지는 쉬폰 케이크',
    },
    {
      id: '007',
      korName: '테디베어 초코 쉬폰',
      engName: 'teddy bear choco chiffon',
      imageUri: require('../../assets/images/menu/bakery/cake/007.png'),
      price: 43000,
      desc: '촉촉하고 달콤한 초코 시트와 부드러운 생크림이 만나 더욱 풍미가 깊은 쉬폰 케이크',
    },
    {
      id: '008',
      korName: '뉴욕 치즈케이크',
      engName: 'newyork cheesecake',
      imageUri: require('../../assets/images/menu/bakery/cake/008.png'),
      price: 44000,
      desc: '크림치즈의 진한 풍미와 촉촉한 식감을 느낄 수 있는 뉴욕 스타일의 치즈케이크',
    },
    {
      id: '015',
      korName: '바스크 치즈케이크',
      engName: 'basque cheese cake',
      imageUri: require('../../assets/images/menu/bakery/cake/015.png'),
      price: 29000,
      desc: '스페인바스크 지역의 전통 치즈케이크로 높은 온도에서 구워내 묵직한 치즈의 향과 맛이 느껴지는 케이크',
    },
    {
      id: '018',
      korName: '아티제 티라미수',
      engName: 'artisée tiramisu',
      imageUri: require('../../assets/images/menu/bakery/cake/018.png'),
      price: 29000,
      desc:
        '부드러운 크림치즈와 진한 풍미의 아티제 바리스타 블렌드 에스프레소의 맛과 향을 담은 시트, 발로나 파우더로 마무리한 시그니처 티라미수 케이크',
    },
    {
      id: '011',
      korName: '클래식 롤 케이크',
      engName: 'classic roll cake',
      imageUri: require('../../assets/images/menu/bakery/cake/011.png'),
      price: 22000,
      desc: '폭신한 시트에 풍미 가득한 에쉬레 버터 크림을 더한 롤케이크',
    },
    {
      id: '004',
      korName: '스트로베리 화이트 롤',
      engName: 'strawberry white roll',
      imageUri: require('../../assets/images/menu/bakery/cake/004.png'),
      price: 24000,
      desc: '달콤한 생딸기와 생크림이 들어있는 부드러운 화이트 스폰지 케이크',
    },
    {
      id: '001',
      korName: '화이트 베어 초코 케이크',
      engName: 'white bear chocolate cake',
      imageUri: require('../../assets/images/menu/bakery/cake/001.png'),
      price: 22000,
      desc: '촉촉하고 부드러운 초코 제누아즈 시트에 달콤한 코코넛 파우더를 더한 베어 케이크',
    },
    {
      id: '009',
      korName: '초코베어 자허토르테',
      engName: 'choco bear sacher torte',
      imageUri: require('../../assets/images/menu/bakery/cake/009.png'),
      price: 26000,
      desc: '살구잼을 레이어드한 초콜릿 시트에 다크 초콜릿을 더한 베어 케이크',
    },
    {
      id: '010',
      korName: '제주 당근 케이크',
      engName: 'jeju carrot cake',
      imageUri: require('../../assets/images/menu/bakery/cake/010.png'),
      price: 24000,
      desc: '제주 당근과 부드러운 크림치즈, 고소한 피칸을 듬뿍 넣은 당근 케이크',
    },
  ],
  breads: [
    {
      id: '001',
      korName: '무화과 스콘',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/001.png'),
      price: 4200,
      desc: '프랑스산 정통 자연 버터와 건조 무화과를 넣어 달콤함을 더한 스콘',
    },
    {
      id: '002',
      korName: '애플파이',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/002.png'),
      price: 3900,
      desc: '바삭한 파이 속에 달콤한 사과 과육과 시나몬을 더한 페이스트리',
    },
    {
      id: '003',
      korName: '슈레드치즈 소시지',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/003.png'),
      price: 4600,
      desc: '통 소시지에 고소한 치즈를 토핑해 든든하게 즐기는 간식 빵',
    },
    {
      id: '004',
      korName: '올리브 치즈 베이글',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/004.png'),
      price: 4200,
      desc: '블랙 올리브와 롤 치즈를 넣어 고소한 치즈와 올리브의 풍미를 느낄 수 있는 베이글',
    },
    {
      id: '005',
      korName: '아티제 크루아상',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/005.png'),
      price: 3400,
      desc: '페이스트리의 결이 살아있고 바삭한 식감을 가진 정통 크루아상',
    },
    {
      id: '006',
      korName: '크로크무슈',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/006.png'),
      price: 4600,
      desc: '부드러운 베샤멜 소스와 고소한 햄과 치즈를 넣어 구워낸 프랑스 식 조리빵',
    },
    {
      id: '007',
      korName: '미니 소시지 브레드(3입)',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/007.png'),
      price: 4600,
      desc: '메이플 시럽을 더한 미니 사이즈의 소시지 브레드',
    },
    {
      id: '008',
      korName: '말돈 소금빵',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/008.png'),
      price: 3500,
      desc: '짭조름한 말돈 소금과 고소한 버터의 풍미를 느낄 수 있는 브레드',
    },
    {
      id: '009',
      korName: '바게트 치즈 식빵',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/009.png'),
      price: 6200,
      desc: '고소한 바게트빵에 롤치즈가 가미되어 담백하고 부드러운 식빵',
    },
    {
      id: '010',
      korName: '치즈 롤',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/010.png'),
      price: 5900,
      desc: '고소한 바게트빵에 롤치즈로 채운 담백하고 쫄깃한 빵',
    },
    {
      id: '011',
      korName: '무화과 크림치즈 브레드',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/bread/011.png'),
      price: 6400,
      desc: '고소한 크림치즈와 달콤한 무화과가 어우러진 브레드',
    },
  ],
  castellas: [
    {
      id: '001',
      korName: '허니 카스텔라',
      engName: 'honey castella',
      imageUri: require('../../assets/images/menu/bakery/castella/001.png'),
      price: 12000,
      desc: '국내산 최고급 벌꿀을 사용하여 만든 부드럽고 달콤한 카스텔라',
    },
    {
      id: '002',
      korName: '흑미 카스텔라',
      engName: 'black rice castella',
      imageUri: require('../../assets/images/menu/bakery/castella/002.png'),
      price: 12000,
      desc: '몸에 좋은 검은 쌀로 만든 부드러운 맛이 일품인 카스텔라',
    },
    {
      id: '003',
      korName: '녹차 카스텔라',
      engName: 'green tea castella',
      imageUri: require('../../assets/images/menu/bakery/castella/003.png'),
      price: 12000,
      desc: '최고급 녹차로 만들어 향긋한 녹차 향이 일품인 카스텔라',
    },
    {
      id: '004',
      korName: '토종 유정란으로 만든 클래식 카스텔라',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/castella/004.png'),
      price: 9800,
      desc: '건강하게 자란 토종닭이 낳아 진한 노란빛을 띤 토종 유정란으로 만든 카스텔라',
    },
    {
      id: '005',
      korName: '넛츠&후르츠 파운드 케이크',
      engName: '',
      imageUri: require('../../assets/images/menu/bakery/castella/005.png'),
      price: 25000,
      desc: '무화과, 살구, 자두 등 다양한 건과일과 아몬드, 호두를 넣은 파운드케이크',
    },
  ],
};
